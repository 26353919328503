<template>
    <section class="main-content-section">
        <div class="grid">

            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div v-if="order.lines.length > 0" class="register-product-summary">
                    <h2 class="page-title">{{selectedProducts}}</h2>
                    <div v-for="line in order.lines" :key="line.id" class="flex justify-space-between register-product">                     
                        <figure>
                            <img :src="line.imageUrl" :alt="line[`name${selectedLanguage}`]">
                        </figure>
                        <div class="flex flex-column justify-end">
                            <h4>{{line[`name${selectedLanguage}`]}}</h4>
                            <div class="flex justify-end">
                                <span>{{amount}}</span>
                                <span>{{line.quantity}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container register-form">
                    <div class="form-header">
                        <h1 class="page-title">{{hello}} {{ username }}</h1>
                        <div class="info">{{weNeedYourEmailaddress}}</div>
                    </div>
                    
                    <form @submit.prevent="submitForm()">
                        <div class="form-row flex flex-column">
                            <label for="email">{{emailAddress}} *</label>
                            <input 
                                id="email" 
                                type="email"
                                v-model.trim="email"
                                @input="$v.email.$touch()"
                                :class="{ error: $v.email.$error }"
                                @blur="saveDataToLocalStorage('email', email)" 
                            />
                            <div v-if="$v.email.$dirty">
                                <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                                <p class="error" v-if="!$v.email.required">{{emailAddress}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="emailConfirmation">{{confirmEmailaddress}} *</label>
                            <input 
                                id="emailConfirmation" 
                                type="email"
                                v-model.trim="emailConfirmation"
                                @input="$v.emailConfirmation.$touch()"
                                :class="{ error: $v.emailConfirmation.$error }"
                                @blur="saveDataToLocalStorage('emailConfirmation', emailConfirmation)" 
                            />
                            <div v-if="$v.emailConfirmation.$dirty">
                                <p class="error" v-if="!$v.emailConfirmation.required">{{confirmEmailaddress}} {{isRequired}}</p>
                                <p class="error" v-if="!$v.emailConfirmation.sameAsEmail">{{incorrectEmailAddressConfirmation}}</p>
                            </div>
                        </div> 

                        <div v-if="isPhoneVisible" class="form-row flex flex-column">
                            <label for="phone">{{phoneNumber}} <span v-if="isPhoneRequired">*</span></label>
                            <template v-if="isPhoneRequired">
                                <input 
                                    type="number" 
                                    id="phone" 
                                    v-model.trim="phone"
                                    @input="$v.phone.$touch()"
                                    :class="{ error: $v.phone.$error }"
                                    @blur="saveDataToLocalStorage('phone', phone)"
                                />
                                <div v-if="$v.phone.$dirty">
                                    <p class="error" v-if="!$v.phone.required">{{phoneNumber}} {{isRequired}}</p>
                                    <p class="error" v-if="!$v.phone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                            
                            <template v-else>
                                <input 
                                    type="number" 
                                    id="phone" 
                                    v-model.trim="phone"
                                    @input="$v.phone.$touch()"
                                    :class="{ error: $v.phone.$error }" 
                                    @blur="saveDataToLocalStorage('phone', phone)" 
                                />
                                <div v-if="$v.phone.$dirty">
                                    <p class="error" v-if="!$v.phone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                        </div>

                        <div v-if="isMobileVisible" class="form-row flex flex-column">
                            <label for="mobilePhone">{{mobileNumber}} <span v-if="isMobileRequired">*</span></label>
                            <template v-if="isMobileRequired">
                                <input 
                                    type="number" 
                                    id="mobilePhone" 
                                    v-model.trim="mobilePhone"
                                    @input="$v.mobilePhone.$touch()"
                                    :class="{ error: $v.mobilePhone.$error }"
                                    @blur="saveDataToLocalStorage('mobilePhone', mobilePhone)"
                                />
                                <div v-if="$v.mobilePhone.$dirty">
                                    <p class="error" v-if="!$v.mobilePhone.required">{{mobileNumber}} {{isRequired}}</p>
                                    <p class="error" v-if="!$v.mobilePhone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>

                            <template v-else>
                                 <input 
                                    type="number" 
                                    id="mobilePhone" 
                                    v-model.trim="mobilePhone"
                                    @input="$v.mobilePhone.$touch()"
                                    :class="{ error: $v.mobilePhone.$error }" 
                                    @blur="saveDataToLocalStorage('mobilePhone', mobilePhone)" 
                                />
                                <div v-if="$v.mobilePhone.$dirty">
                                    <p class="error" v-if="!$v.mobilePhone.valid">{{invalidSpecialCharacters}}</p>
                                </div>
                            </template>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-space-between">
                            <button @click="navigateToPreviousStep()" class="btn btn-secondary">{{previousStep}}</button>
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{nextStep}} (2/3)</button>
                        </div>                      
                    </form>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import WarningMessageModal from '@/components/modals/WarningMessageModal';

export default {
    name: 'RegisterStepTwo',
    components: {WarningMessageModal},
    data() {
        return {
            email: '',
            emailConfirmation: '',
            phone: '',
            mobilePhone: '',
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false
        }
    },

    created() {
        this.setDocumentTitle();

        if (!this.order && this.$route.query.orderId) {
            this.$store.dispatch('fetchOrder', this.$route.query.orderId);
        }

        this.email = this.$store.getters.getEmail || localStorage.getItem('email');
        this.emailConfirmation = this.$store.getters.getEmailConfirmation || localStorage.getItem('emailConfirmation');
        this.phone = this.$store.getters.getPhone || localStorage.getItem('phone');
        this.mobilePhone = this.$store.getters.getMobile || localStorage.getItem('mobilePhone');
    },

    updated() {
        this.setDocumentTitle();
    },
    
    computed: {
        ...mapGetters([
            'metaTitle',
            'selectedLanguage',
            // Setttings
            'isPhoneVisible',
            'isPhoneRequired',
            'isMobileVisible',
            'isMobileRequired',
            // Texts
            'hello',
            'weNeedYourEmailaddress',
            'emailAddress',
            'validEmailAddress',
            'confirmEmailaddress',
            'incorrectEmailAddressConfirmation',
            'phoneNumber',
            'mobileNumber',
            'previousStep',
            'nextStep',
            'indicatesRequiredField',
            'isRequired',
            'notAllRequiredFieldsAreCompleted',
            'amount',
            'selectedProducts',
            'createYourAccount',
            'invalidSpecialCharacters'
        ]),
        ...mapGetters({
            username: 'getUsername',
            order: 'getOrder',
            orderId: 'getOrderId'
        }),
    },

    methods: {
        setDocumentTitle() {
            document.title = this.createYourAccount + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        saveDataToStore() {
            this.$store.dispatch('setEmail', this.email);
            this.$store.dispatch('setEmailConfirmation', this.emailConfirmation);
            this.$store.dispatch('setPhone', this.phone);
            this.$store.dispatch('setMobile', this.mobilePhone);
        },

        saveDataToLocalStorage(key, data) {
            localStorage.setItem(key, data);
        },

        navigateToPreviousStep() {
            this.saveDataToStore();
            this.$router.push(`/register/step-1?orderId=${this.$route.query.orderId}`);
        },

        submitForm() {
            // Warning modal message handlers and prevent navigation to next step 
            if (this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.emailConfirmation.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.confirmEmailaddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isPhoneVisible && this.isPhoneRequired && this.$v.phone.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.phoneNumber + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.isMobileVisible && this.isMobileRequired && this.$v.mobilePhone.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.mobileNumber + ' ' + this.isRequired;
                this.showModal();
                return;
            }
            
            // Store data in store if conditions are met
            this.saveDataToStore();

            // Go to next step
            this.$router.push(`/register/step-3?orderId=${this.$route.query.orderId}`);
        }
    },

    validations: {
        email: { email, required },
        emailConfirmation: { email, required, sameAsEmail: sameAs("email") },
        phone: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        },
        mobilePhone: { 
            required,
            valid: (value) => {
                const containsSpecial = /[#?!@$%^&*-/";|<>+=\\(){}]/.test(value);
                return(
                    !containsSpecial
                );
            } 
        }
    },
}
</script>